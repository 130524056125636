<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!-- Start Header Area -->
    <div v-if="data">
      <Header>
        <img
          slot="logo"
          src="../../assets/images/logo/Logo-Inflow-Hor-BB@2x.png"
          style="max-width:80%; max-height:80%"
        />
      </Header>
      <!-- End Header Area -->

      <!-- Start Breadcrump Area  -->
      <div
        class="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--1"
        data-black-overlay="6"
      >
        <v-container>
          <v-row>
            <v-col lg="12">
              <div class="breadcrumb-inner pt--100 pt_sm--40 pt_md--50">
                <h2 class="heading-title">Blog Inflow</h2>
                <ul class="page-list">
                  <li><router-link to="/">Inicio</router-link></li>
                  <li class="current-page">Blog</li>
                </ul>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Breadcrump Area  -->

      <!-- Start blog Area  -->
      <div class="rn-blog-area rn-section-gap bg_color--1">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="section-title section-title--2 text-center">
                <h2 class="heading-title">Artículos</h2>
                <p>
                  {{ data.data.attributes.updatesDescription }}
                </p>
              </div>
            </v-col>
          </v-row>
          <!-- End .row -->
          <BlogThree2 :pagination="true" />
        </v-container>
      </div>
      <!-- Start blog Area  -->

      <!-- Start blog Area  -->
      <!-- 
      <div class="rn-blog-area rn-section-gap bg_color--5">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="section-title section-title--2 text-left">
                <h2 class="heading-title">Blogs Populares</h2>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration.
                </p>
              </div>
            </v-col>
          </v-row>
          <BlogTwo />
        </v-container>
      </div>
      -->
      <!-- Start blog Area  -->

      <!-- Start blog Area  
      <div class="rn-blog-area rn-section-gap bg_color--1">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="section-title section-title--2 text-center">
                <h2 class="heading-title">Related Post</h2>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration.
                </p>
              </div>
            </v-col>
          </v-row>
          <Blog />
        </v-container>
      </div>
      -->
      <Footer />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../../components/header/Header";
import BlogThree from "../../components/blog/BlogThree";
import BlogThree2 from "../../components/blog/BlogThree-2";
import BlogTwo from "../../components/blog/BlogTwo";
import Blog from "../../components/blog/Blog";
import Footer from "../../components/footer/Footer";
export default {
  components: {
    Header,
    BlogThree,
    BlogThree2,
    BlogTwo,
    Blog,
    Footer,
  },
  data() {
    return {
      page: 1,
      data: null,
      overlay: false,
    };
  },
  mounted() {
    this.getSingleBlog();
  },
  methods: {
    async getSingleBlog() {
      try {
        const r = await axios(`${this.$apiUrl}/api/blog-single`);
        this.data = r.data;
        this.overlay = false;
      } catch (err) {
        console.log(err);
        this.overlay = false;
      }
    },
  },
};
</script>
